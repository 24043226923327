@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src:
      url('/fonts/lato-v17-latin-ext_latin-regular.woff2') format('woff2'),
      url('/fonts/lato-v17-latin-ext_latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src:
      url('/fonts/lato-v17-latin-ext_latin-700.woff2') format('woff2'),
      url('/fonts/lato-v17-latin-ext_latin-700.woff') format('woff');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src:
      url('/fonts/lato-v17-latin-ext_latin-900.woff2') format('woff2'),
      url('/fonts/lato-v17-latin-ext_latin-900.woff') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/inter-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/inter-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/inter-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/inter-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/inter-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/inter-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/inter-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/inter-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/inter-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/inter-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  :root {
    --inset-t: 0px;
    --inset-r: 0px;
    --inset-b: 0px;
    --inset-l: 0px;
    --font-var-wght: 415; /* Normally 400 but this looks more balanced between Windows/Mac and light/dark themes */
  }

  html {
    color-scheme: light;
    font-weight: normal;
    font-variation-settings: 'wght' var(--font-var-wght);
    backface-visibility: hidden;

    &.dark {
      color-scheme: dark;
    }
  }

  html,
  :host {
    /* https://github.com/tailwindlabs/tailwindcss/pull/12299 */
    -webkit-tap-highlight-color: revert;

    /* NOTE: override `-webkit-text-size-adjust: 100%;` from `@tailwind base;` */
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  body {
    @apply text-gray-900 dark:text-gray-50;
  }

  b,
  strong {
    @apply font-bold;
  }

  .gradient-fade {
    @apply bg-gradient-to-b from-gray-50 dark:from-gray-900;
    background-repeat: no-repeat;
    background-size: 100% 1024px;
  }

  input[type='number'].hide-stepper::-webkit-inner-spin-button {
    @apply hidden;
  }

  input[type='number'].hide-stepper {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button {
    @apply ml-2 h-[18px] w-[18px] appearance-none opacity-0 touch-device:hidden;
  }

  select:disabled {
    /* to override user agent styles of 70% */
    @apply opacity-100;
  }

  /** Firefox doesn’t add any margin between spin button and text **/
  @supports (-moz-appearance: none) {
    input[type='number'].text-right:not(.hide-stepper) {
      @apply text-left;
    }
  }
}

@layer components {
  .table-row:hover td {
    @apply bg-blue-100;
  }

  .table-row td:first-child {
    @apply rounded-l;
  }

  .table-row td:last-child {
    @apply rounded-r;
  }

  /**
   * Unsupported browser
   * Don't use apply/TW classes, so this works in all browsers
   */

  #notifications-container {
    display: none;
    position: sticky;
    top: 0px;
    z-index: 100000;
  }

  #notifications-container > div {
    background: #fd656a;
    color: #ffffff;
    font-family: Lato, Verdana, sans-serif;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
  }

  #notifications-container > div a {
    color: #fff;
    text-decoration: underline;
  }

  #notifications-container > div a:hover,
  #notifications-container > div a:active,
  #notifications-container > div a:focus {
    text-decoration: none;
  }

  #qc-cmp2-persistent-link {
    display: none !important;
  }
}

@layer utilities {
  .custom-scrollbar {
    --scrollbar-offset: 6px;
    --scrollbar-offset-top: var(--scrollbar-offset);
    --scrollbar-offset-bottom: var(--scrollbar-offset);
    --scrollbar-offset-left: var(--scrollbar-offset);
    --scrollbar-offset-right: var(--scrollbar-offset);
    --scrollbar-radius: theme(spacing[0.5]);

    --track-bg: transparent;
    --track-bg-hover: rgba(rgbstring(theme(backgroundColor.gray.200)), 0.3);
    --thumb-bg: theme(backgroundColor.gray.100);
    --thumb-bg-hover: rgba(rgbstring(theme(backgroundColor.gray.200)), 0.9);

    .dark & {
      --track-bg: transparent;
      --track-bg-hover: theme(backgroundColor.gray.900);
      --thumb-bg: theme(backgroundColor.gray.600);
      --thumb-bg-hover: rgba(rgbstring(theme(backgroundColor.gray.500)), 0.9);
    }

    ::-webkit-scrollbar {
      @apply h-3.5 w-3.5;
    }

    ::-webkit-scrollbar-track {
      @apply rounded-[--scrollbar-radius] border-[theme(backgroundColor.primary)];
      @apply bg-[--track-bg] hover:bg-[--track-bg-hover];
    }

    ::-webkit-scrollbar-track:vertical {
      @apply mb-[--scrollbar-offset-bottom] mt-[--scrollbar-offset-top] border-r-[6px] border-r-solid;
      border-top-right-radius: 58% var(--scrollbar-radius);
      border-bottom-right-radius: 58% var(--scrollbar-radius);
    }

    ::-webkit-scrollbar-track:horizontal {
      @apply ml-[--scrollbar-offset-left] mr-[--scrollbar-offset-right] border-b-[6px] border-b-solid;
      border-bottom-left-radius: var(--scrollbar-radius) 58%;
      border-bottom-right-radius: var(--scrollbar-radius) 58%;
    }

    ::-webkit-scrollbar-thumb {
      @apply rounded-[--scrollbar-radius] border-transparent bg-clip-padding;
      @apply bg-[--thumb-bg] hover:bg-[--thumb-bg-hover];
    }

    ::-webkit-scrollbar-thumb:vertical {
      @apply border-r-[6px] border-r-solid;
      border-top-right-radius: 58% var(--scrollbar-radius);
      border-bottom-right-radius: 58% var(--scrollbar-radius);
    }

    ::-webkit-scrollbar-thumb:horizontal {
      @apply border-b-[6px] border-b-solid;
      border-bottom-left-radius: var(--scrollbar-radius) 58%;
      border-bottom-right-radius: var(--scrollbar-radius) 58%;
    }
  }

  /* https://css-tricks.com/bold-on-hover-without-the-layout-shift/ */
  .label-with-bold-weight {
    @apply inline-flex flex-col;

    &::after {
      content: attr(data-text);
      content: attr(data-text) / '';
      @apply pointer-events-none invisible h-0 select-none overflow-hidden font-bold;

      @media speech {
        @apply hidden;
      }
    }
  }

  .search-list {
    @apply flex grow flex-col overflow-auto;

    .search-item {
      @apply flex;
    }

    @supports (grid-template-columns: subgrid) {
      @apply grid grid-cols-[auto_1fr_auto];

      .search-item {
        @apply col-span-3 grid grid-cols-subgrid;

        span:nth-child(n + 3) {
          @apply justify-self-end;
        }
      }
    }
  }
}

/**
  * Shepherd tour styles — these need to be outside of `@layer` so they are not purged
  */
.shepherd-modal-overlay-container {
  @apply invisible fixed bottom-0 left-0 right-0 top-0 z-dialog h-screen w-screen overflow-hidden stroke-pink-300 stroke-2 opacity-0;
}

.shepherd-modal-is-visible {
  @apply visible fill-gray-900 opacity-50;
}

.shepherd-footer {
  @apply -mt-14 flex justify-end space-x-2 p-4;
}

.shepherd-text > div > h5 {
  @apply mt-0;
}

/**
  * CSS gap property for flexbox is recent feature with weak support in older browsers https://caniuse.com/flexbox-gap,
  * but we can emulate it's behaviour with little bit of css hacking
  * source of hack: https://coryrylan.com/blog/css-gap-space-with-flexbox
  */

.emulated-flex-gap {
  --emulated-flex-gap: 4px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--emulated-flex-gap)) 0 0 calc(-1 * var(--emulated-flex-gap));
  width: calc(100% + var(--emulated-flex-gap));
}

.emulated-flex-gap > * {
  margin: var(--emulated-flex-gap) 0 0 var(--emulated-flex-gap);
}
